
export default {
    UPDATE_TRIP_AND_TRAVELLER_DETAILS: (state, value) => {
        // sessionStorage.setItem("passengerDetail",JSON.stringify(value))
        state.tripAndTravellerDetails = value
    },

    SET_PENDING_TRIPS: (state,value) =>{
        state.pendingTrips = value
    },
    SET_ONE_WAY_TRIPS: (state,value)=>{
        state.oneWayTrips = value
    },
    SET_USER_SELECTED_TRIP: (state, value)=>{
        sessionStorage.setItem("userSelectedTrip", JSON.stringify(value))
        state.userSelectedTrip = value
    },
    SET_USER_SELECTED_PAYMENT_OPTION: (state, value)=>{
        state.userSelectedPaymentOption = value
    },
    SET_REFRESH_TRIP_PAGE:(state, value)=>{
        state.refresh = value
    }
};
