import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";


const state = {
    tripAndTravellerDetails: {
        seats:[]
    },
    pendingTrips:[],
    oneWayTrips:[],
    userSelectedTrip:{},
    userSelectedPaymentOption:null,
    refresh:true
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
